import React, { useCallback, useEffect, useState } from "react";
import "./collection.css";
import Modal from "../Components/modal";
import api from "../../axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { patchCollection, deleteCollection } from "./logic";
import { AddSquare, Trash } from "iconsax-react";
import { toast } from "react-toastify";

function DataRow(props) {
  const handleAddToSiteClick = (e) => {
    // Prevent the click event from bubbling up to the parent <Link> component
    e.stopPropagation();

    // Prevent the default behavior of the link
    e.preventDefault();

    // Execute the onClick function passed as a prop
    if (props.onClick) {
      props.onClick();
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.onDelete) {
        props.onDelete();
    };
    toast.warning("Item deleted successfully");
  };
  
  return (
    <Link className="title" to={"/editcollection/" + props.id}>
      <div className="data-row-container w-full flex items-center justify-between border border-[--app-border] px-4 py-5 rounded-xl mb-2">
        <div className="data-row-title w-1/5 text-left">
          <h4 className="text-xl font-bold">{props.title}</h4>
        </div>
        <div className="videos w-1/5 text-center">
          <h4 className="font-bold text-2xl">{props.videos}</h4>
        </div>
        <div className="date w-1/5 text-center">
          <h4 className="font-bold text-2xl">{/* {props.date} */} 0</h4>
        </div>
        <div className="date w-1/5 text-center">
          <h4 className="font-bold text-2xl">{/* {props.type} */}0</h4>
        </div>
        <div className="data-row-action w-1/5 flex items-center justify-center gap-x-4">
          <button
            className="font-semibold bg-[--accent-3] px-3 py-2 rounded-2xl"
            onClick={handleAddToSiteClick}
          >
            ADD TO SITE
          </button>
          <button 
            className="px-2 py-2 bg-[--accent-1] rounded-xl"
            onClick={handleDeleteClick}
          >
            <Trash 
                // size="1.75rem" 
                color="#ffffff" 
            />
          </button>
        </div>
      </div>
    </Link>
  );
}

export default function Collection() {
  const script = process.env.REACT_APP_SCRIPT_URL;
  const [listData, setListData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [switchModalTab, setSwitchModalTab] = useState(false);
  const [orientationType, setOrientationType] = useState("WIDGET");
  const patchOptions = ["WIDGET", "INLINE", "CAROUSEL"];
  const [selectedCollections, setSelectedCollections] = useState("");
  const [selectedCollectionCode, setSelectedCollectionCode] = useState("");
  const [nextUrl, setNextUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const modalStyle = {
    height: "calc(100% - 50px)",
    width: "60%",
    background: "var(--modal-bg)",
  };

  const getData = useCallback (async (url = "/collections/") => {
    setLoading(true);
    try {
      // const startTime = Date.now();
      const resp = await api.get(url);
      // const endTime = Date.now();
      // const duration = endTime - startTime; // duration is in millisecs

      // if (duration >= 2000) {
      //   // if api-call takes 2+ secs, simulating a delay:
      //   await new Promise((resolve) => setTimeout(resolve, 2000));
      // }
      const data = resp.data.results;
      const nextUrl = resp.data.next;

      setListData(prevData => [...prevData, ...data]);

      setNextUrl(nextUrl || null);
    } catch (err) {
      console.error("Error loading next data:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (listData.length === 0) {
      getData();
    }
  }, [getData, listData.length]);

  useEffect(() => {
    const handleScroll = async() => {
      const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1;

      if (bottom && nextUrl && !loading) {
        await getData(nextUrl);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nextUrl, loading]);

  useEffect(() => {
    if (!modalIsOpen) {
      setSwitchModalTab(false);
      setSelectedCollections("");
    }
  }, [switchModalTab, orientationType, modalIsOpen]);

  const handleCopy = (className) => {
    const query = "." + className;
    const preContent = document.querySelector(query).innerText;
    navigator.clipboard
      .writeText(preContent)
      .then(() => {
        console.log("Copied!");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const handleDelete = (id) => {
    deleteCollection(id, () => {
        setListData((prevListData) => prevListData.filter((item) => item.id !== id));
    });
  };

  return (
    <>
      <Modal
        onModalClose={() => {
          setSwitchModalTab(false);
        }}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        style={modalStyle}
      >
        <div className="modal-container h-full p-12">
          <div className={`modal-head ${!switchModalTab ? "h-[20%]" : ""}`}>
            <span className="text-4xl font-bold">
              {switchModalTab
                ? "Step 2 of 2: Add to Site"
                : "Step 1 of 2: Select Display Type"}
            </span>
          </div>

          {switchModalTab ? (
            <div className="modalBody" style={{ height: "90%" }}>
              <div className="modal-flex h-full">
                <div className="modal-section-1 h-full flex flex-col items-start justify-around overflow-y-scroll">
                  <div className="sections">
                    <div className="steps">
                      <h4 className="normal">
                        <strong>Step 1:</strong> First add the chilly-pepper
                        script to your website header. You only do this once so
                        ignore this step if you've already added it.
                      </h4>
                    </div>
                    <div className="codes mt-2 bg-[--modal-code-bg] overflow-x-auto p-5 rounded-xl relative">
                      <div className="snippet-head absolute top-2 right-2">
                        <button
                          className="copy-button border-2 border-[--app-border] rounded-md px-3 text-[--secondary-txt] font-semibold"
                          onClick={() => handleCopy("step-1-snippet")}
                        >
                          copy
                        </button>
                      </div>
                      <pre className="normal step-1-snippet p-5 text-wrap">
                        {"<script src='" + script + "' defer></script>"}
                      </pre>
                    </div>
                  </div>
                  <div className="sections">
                    <div className="steps">
                      <h4 className="normal">
                        <strong>Step 2:</strong> Add your carousel snippet where
                        you want it to display. This will showcase all your
                        videos within one carousel. Any updates or new videos
                        you create in your admin will automatically be displayed
                        in your carousel.
                      </h4>{" "}
                    </div>
                    <div className="codes mt-2 bg-[--modal-code-bg] p-5 rounded-xl relative">
                      <div className="snippet-head absolute top-2 right-2">
                        <button
                          className="copy-button border-2 border-[--app-border] rounded-md px-3 text-[--secondary-txt] font-semibold"
                          onClick={() => handleCopy("step-2-snippet")}
                        >
                          copy
                        </button>
                      </div>
                      <pre className="normal step-2-snippet p-5 text-wrap">
                        {"<div id='chilli-pepperr'"}
                        <br />
                        {"cp-collection-code=" + selectedCollectionCode}
                        <br />
                        {"cp-orientation-type=" + orientationType}
                        <br />
                        {"cp-username=" +
                          String(
                            localStorage.getItem("username")
                              ? localStorage.getItem("username")
                              : ""
                          )}
                        <br />
                        {"></div>"}
                      </pre>
                    </div>
                  </div>
                </div>
                <div className="modalSection2"></div>
              </div>
              <div className="buttons-holder flex items-center justify-end gap-x-2 mt-2">
                <button
                  className="collection-modal-cont-button border-2 border-[--app-border] text-[--secondary-txt] px-3 py-2 rounded-2xl font-semibold"
                  onClick={() => {
                    setSwitchModalTab(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="collection-modal-cont-button border-2 border-[--accent-1] bg-[--accent-1] text-white px-3 py-2 rounded-2xl font-semibold"
                  onClick={() => {
                    patchCollection(
                      orientationType,
                      selectedCollections,
                      () => {
                        setModalIsOpen(false);
                        setListData([]);
                      }
                    );
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          ) : (
            <div className="modal-body h-full">
              <div className="head text-base font-medium mb-4">
                Select the way you want your video(s) to display to continue.
                Don't worry, you can use all of these simultaneously.
              </div>
              <div className="body h-[70%] w-full flex flex-col items-center gap-y-8 overflow-x-auto">
                <div className="card-container h-full w-full flex sm:flex-col md:flex-row items-center justify-around gap-x-4 sm:gap-y-4">
                  <button
                    className={`${
                      orientationType === patchOptions[0] &&
                      "selection-card selected-orientation bg-[--accent-1] text-white"
                    } h-full w-full border border-[--app-border] rounded-2xl p-5 text-xl font-semibold`}
                    onClick={() => {
                      setOrientationType(patchOptions[0]);
                    }}
                  >
                    {patchOptions[0]}
                  </button>
                  <button
                    className={`${
                      orientationType === patchOptions[1] &&
                      "selection-card selected-orientation bg-[--accent-1] text-white"
                    } h-full w-full border border-[--app-border] rounded-2xl p-5 text-xl font-semibold`}
                    onClick={() => {
                      setOrientationType(patchOptions[1]);
                    }}
                  >
                    {patchOptions[1]}
                  </button>
                  <button
                    className={`${
                      orientationType === patchOptions[2] &&
                      "selection-card selected-orientation bg-[--accent-1] text-white"
                    } h-full w-full border border-[--app-border] rounded-2xl p-5 text-xl font-semibold`}
                    onClick={() => {
                      setOrientationType(patchOptions[2]);
                    }}
                  >
                    {patchOptions[2]}
                  </button>
                </div>
                <div className="buttonContainer">
                  <button
                    className="collection-modal-body-button bg-[--accent-1] text-white px-3 py-2 rounded-2xl font-semibold"
                    onClick={() => {
                      setSwitchModalTab(true);
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div className="collections-container w-full pt-20">
        <div className="collection-head flex items-center justify-between mb-12">
          <div className="head-title">
            <h1 className="text-4xl font-bold">COLLECTIONS</h1>
          </div>
          <div className="create-collection-container">
            <Link to={"/createcollection"}>
              <button className="create-collection-btn flex items-center justify-center gap-x-4 bg-[--accent-1] px-3 py-2 rounded-xl text-white">
                <span>
                  {" "}
                  <AddSquare size={"1.75rem"} color="#ffffff" />{" "}
                </span>{" "}
                <span className="text-xl font-bold">CREATE</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="collection-body">
          <div className="flex items-start justify-evenly mb-12">
            <div className="collections-title w-1/5 text-left">
              <h3 className="text-xl font-medium">Title</h3>
            </div>
            <div className="collections-videos w-1/5 text-center">
              <h3 className="text-xl font-medium">Videos</h3>
            </div>
            <div className="collections-date-created w-1/5 text-center">
              <h3 className="text-xl font-medium">Date Created</h3>
            </div>
            <div className="collections-playtime w-1/5 text-center">
              <h3 className="text-xl font-medium">Playtime</h3>
            </div>
            <div className="collections-action w-1/5 text-center">
              <h3 className="text-xl font-medium">Action</h3>
            </div>
          </div>
          <div className="DataHolder">
            {listData.length === 0
              ? ""
              : listData.map((data, index) => (
                  <DataRow
                    title={data.name}
                    id={data.id}
                    key={index}
                    videos={data.videos.length}
                    type={data.config.type}
                    onClick={() => {
                      setSelectedCollections(data.id);
                      setSelectedCollectionCode(data.code);
                      setModalIsOpen(true);
                      setOrientationType(data.config.type);
                    }}
                    onDelete={() => {
                        // console.log(`Delete collection with id: ${data.id}`);
                        handleDelete(data.id);
                    }}
                  />
                ))}
          </div>
          {loading ? (<p className="text-xl font-semibold my-10 mx-10">Loading...</p>) : (<></>)}
        </div>
      </div>
    </>
  );
}
