import { parsePath } from 'react-router-dom'
import api from '../../axiosConfig'
function saveCreateVideo(title, desc, vidlink, coverimage, productlist, isShoppable, isTitle, videoType, duration, navigate, videoUrl = undefined) {
    console.log("Running saveCreateVideo...");

    const parms = {
        "title": title,
        "description": desc,
        "video": vidlink,
        "coverImage": coverimage,
        "video_url": videoUrl,
        "video_type": videoType,
        "is_shoppable": isShoppable,
        "is_title": isTitle,
        "duration": duration,
        "products": [
            ...productlist
        ],
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            }
        }, 
    }

    if (videoUrl != undefined) {
        // console.log("VideoUrl added...", videoUrl);
        parms.video_url = videoUrl;
    }
    
    console.log(parms)
    const res = api.post('/videos/', parms).then(function (response) {
        navigate('/videomanager')
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res

}

function putCreateVideo(title, desc, vidlink, coverimage, productlist,isShoppable, isTitle, videoType, duration, navigate, id) {
    const parms = {
        "title": title,
        "description": desc,
        "video": vidlink,
        "coverImage": coverimage,
        "video_type": videoType,
        "is_shoppable": isShoppable,
        "is_title": isTitle,
        "duration": duration,
        "products": [
            ...productlist
        ],
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            }
        }
    }

    console.log(parms)
    const res = api.put('/videos/' + id + "/", parms).then(function (response) {
        navigate('/videomanager')
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res

}

function patchCreateVideo(productlist, id) {
    const parms = {
        "products": [
            ...productlist
        ],
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            }
        }
    }

    console.log(parms)
    const res = api.patch('/videos/' + id + "/", parms).then(function (response) {
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res
}

export { putCreateVideo, saveCreateVideo, patchCreateVideo }