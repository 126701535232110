import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./videomanager.css";
import api from "../../axiosConfig";
import { AddSquare, Trash } from "iconsax-react";
import { getPreSignedUrl } from "../create-video/s3-upload";
import { deleteVideo } from "./logic.js";
import { toast } from "react-toastify";

function DataRow(props) {
  const [coverImage, setCoverImage] = useState("");

  useEffect(() => {
    async function fetchurl(key) {
      const coverImage = await getPreSignedUrl(key);
      setCoverImage(coverImage.url);
    }
    fetchurl(props.coverImage);
  }, []);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (props.onDelete) {
      props.onDelete();
    }
    toast.warning("Item deleted successfully");
  };

  const formatPlayTime = (seconds) => {
    seconds = Math.trunc(seconds);
    if (seconds < 60) {
        return `${seconds} sec`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    } else {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} hrs ${minutes} min`;
    }
  }

  return (
    <Link to={`/editvideo/${props.id}`} className="">
      <div className="data-title-row w-full flex items-center justify-between border border-[--app-border] p-4 rounded-xl mb-2">
        <div className="vide-data-title w-2/6 text-center flex items-center justify-start gap-x-2">
          <div className="h-24 w-24 bg-slate-200">
          <img
            src={coverImage || "https://via.placeholder.com/100"}
            alt="video thumbnail"
            srcSet=""
            className="object-cover h-24 w-24 max-w-none"
          />
          </div>
          <div className="flex flex-col items-start ml-1">
            <h4 className="text-l text-left font-bold">{props.title}</h4>
            <p className="text-s text-left text-wrap line-clamp-2 font-medium">{props.desc}</p>
          </div>
        </div>
        <div className="video-data-plays w-1/6 text-center">
          <h4 className="font-semibold text-xl">{props.plays}</h4>
        </div>
        <div className="video-data-clicks w-1/6 text-center">
          <h4 className="font-semibold text-xl">{props.clicks}</h4>
        </div>
        <div className="video-data-playtime w-1/6 text-center">
          <h4 className="font-semibold text-xl">{formatPlayTime(props.playtime)}</h4>
        </div>
        <div className="video-data-action w-1/6 flex items-center justify-center gap-x-4">
          <button className="font-semibold bg-[--accent-3] px-3 py-2 rounded-2xl">
            VIEW MORE
          </button>
          <button
            className="px-2 py-2 bg-[--accent-1] rounded-xl"
            onClick={handleDeleteClick}
          >
            <Trash size="1.75rem" color="#ffffff" />
          </button>
        </div>
      </div>
    </Link>
  );
}

export default function VideoManager() {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [nextUrl, setNextUrl] = useState(null);
  
  const getData = useCallback (async (url = "/videos/stats") => {
    setLoading(true);
    try {
      // const startTime = Date.now();
      const resp = await api.get(url);
      // const endTime = Date.now();
      // const duration = endTime - startTime; // duration is in millisecs

      // if (duration >= 2000) {
      //   // if api-call takes 2+ secs, simulating a delay:
      //   await new Promise((resolve) => setTimeout(resolve, 2000));
      // }
      const data = resp.data;
      // const nextUrl = resp.data.next;

      setListData(prevData => [...prevData, ...data]);

      // setNextUrl(nextUrl || null);
    } catch (err) {
      console.error("Error getting data:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (listData.length === 0) getData();
  }, [getData, listData.length]);

  // useEffect(() => {
  //   const handleScroll = async() => {
  //     const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1;

  //     if (bottom && nextUrl && !loading) {
  //       await getData(nextUrl);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [nextUrl, loading, getData]);

  const handleDelete = (id) => {
    deleteVideo(id, () => {
      setListData((prevListData) => prevListData.filter((item) => item.id !== id));
    });
  };

  return (
    <div className="video-manager-container w-full pt-20">
      <div className="video-manager-head flex items-center justify-between mb-12">
        <div className="video-manager-head-title">
          <h1 className="text-4xl font-bold">PRODUCT MANAGER</h1>
        </div>
        <div className="video-manager-create-button">
          <Link to={"/createvideo"}>
            <button className="bg-[--accent-1] font-bold text-xl px-4 py-3 text-white rounded-2xl flex items-center justify-center gap-x-4">
              <span>
                <AddSquare size={"1.75rem"} color="#ffffff" />
              </span>
              <span>VIDEO</span>
            </button>
          </Link>
        </div>
      </div>
      {!listData || listData.length === 0 ? (
        <div className="video-manager-Body">
          {/* <div className="VideoManager-CreateButton" onClick={() => {
                    navigate("/createvideo")
                }}>Create</div> */}
        </div>
      ) : (
        <div
          className="
            collection-body w-full"
        >
          <div className="flex items-start justify-evenly mb-12">
            <div className="video-manager-title w-2/6 text-left">
              <h3 className="text-xl font-medium">Title</h3>
            </div>
            <div className="video-manager-plays w-1/6 text-center">
              <h3 className="text-xl font-medium">Plays</h3>
            </div>
            <div className="video-manager-clicks w-1/6 text-center">
              <h3 className="text-xl font-medium">Clicks</h3>
            </div>
            <div className="video-manager-playtime w-1/6 text-center">
              <h3 className="text-xl font-medium">Playtime</h3>
            </div>
            <div className="video-manager-action w-1/6 text-center">
              <h3 className="text-xl font-medium">Action</h3>
            </div>
          </div>
          <div className="DataHolder">
            {!listData || listData.length === 0? (
              <div>
                <h2>You don't have any Shoppable Videos yet.</h2>
              </div>
            ) : (
              listData.map(function (data, index) {
                return (
                  <DataRow
                    title={data.video_details.title}
                    desc={data.video_details.description}
                    id={data.video_details.id}
                    key={index}
                    products={data.video_details.products.length}
                    coverImage={data.video_details.coverImage}
                    plays={data.total_plays}
                    clicks={data.clicks}
                    playtime={data.playtime}
                    onDelete={() => {
                      handleDelete(data.id);
                    }}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      {loading ? (<p className="text-xl font-semibold my-10 mx-10">Loading...</p>) : (<></>)}
    </div>
  );
}
