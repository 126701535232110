import React, { useState } from 'react'
import { Routes, Route, Link } from "react-router-dom";
import { Home2, Video, VideoPlay, VideoVertical, Profile } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import './holder.css'

import Home from '../home/home.js';
import MenuButton from '../Components/menuButton';
import Upload from '../upload/upload.js';
import VideoManager from '../videomanager/videomanager.js';
import Createvideo from '../create-video/createvideo.js';
import Collection from '../collection/collection.js';
import ProfileSection from '../profile/profile.js';
import Settings from '../settings/settings.js';
import CreateCollection from '../createcollection/createcollection.js';
import Analytics from '../analytics/analytics';
import ListVideoLibrary from '../upload/listvideolibrary.js';
import { logout } from '../redux/actions/authAction.js';

function TopMenu() {
    const dispatch = useDispatch();
    return (
        <div className='top-menu'>
            <div className='logo'>
                <Link to={'/'}>ChillyPepper</Link>
            </div>
            <div className='search hidden'>
                <input type='text' placeholder='Search' />
            </div>
            <div className='profile flex items-center justify-around gap-x-2'>
                <div className='before-profile-icon'>
                    <Link to={'/profile'}>
                        <button className='text-base text-[--secondary-txt] font-semibold px-3 py-2 rounded-2xl'>{localStorage.getItem('username') ? localStorage.getItem('username') : ""}</button>
                    </Link>
                </div>
                <div className='profile-icon'>
                    <button className='logout-button bg-[--accent-1] text-white font-semibold  px-3 py-2 rounded-2xl' type="button" onClick={()=>dispatch(logout())}>Logout</button>
                </div>
            </div>
        </div>
    )
}

function SideMenu() {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className={`side-menu ${isHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className='middle-menu-items'>
                <MenuButton Icon={<Home2 size={'1.75rem'} />} goto={'/'} name={`${isHovered ? 'Home' : ''}`} />
                {/* TODO */}
                <MenuButton Icon={<Video size={'1.75rem'} />} goto={'/library'} name={`${isHovered ? 'Video Library' : ''}`} />
                <MenuButton Icon={<VideoPlay size={'1.75rem'} />} goto={'/videomanager'} name={`${isHovered ? 'Product Manager' : ''}`} />
                <MenuButton Icon={<VideoVertical size={'1.75rem'} />} goto={'/collection'} name={`${isHovered ? 'Collection' : ''}`} />
            </div>
            <div className='bottom-menu-items'>
                <MenuButton Icon={<Profile size={'1.75rem'} />} goto={'/profile'} name={`${isHovered ? 'Profile' : ''}`} />
                {/* TODO */}
                {/* <MenuButton Icon={<Setting size={'1.75rem'} />} goto={'/settings'} name={`${isHovered ? 'Settings' : ''}`} /> */}
            </div>
        </div>
    );
}


function MainHolder() {
    return (
        <div className="main-holder">
            <Routes>
                <Route exact path='' element={<Home />}></Route>
                <Route path='/library' element={<ListVideoLibrary></ListVideoLibrary>}></Route>
                <Route path='/upload' element={<Upload></Upload>}></Route>
                <Route path='/videomanager' element={<VideoManager></VideoManager>}></Route>
                <Route path='/createvideo' element={<Createvideo></Createvideo>}></Route>
                <Route path='/collection' element={<Collection></Collection>}></Route>
                <Route path='/profile' element={<ProfileSection />}></Route>
                <Route path='/settings' element={<Settings></Settings>}></Route>
                <Route path='/createcollection' element={<CreateCollection></CreateCollection>}></Route>
                <Route path='/editvideo/:id' element={<Createvideo></Createvideo>}></Route>
                <Route path='/editcollection/:id' element={<CreateCollection></CreateCollection>}></Route>
                <Route path='/analytics' element={<Analytics></Analytics>}></Route>
            </Routes>
        </div>
    );
}

export default function Holder() {
    return (
        <>
            <TopMenu />
            <div className='main-component-container'>
                <div className='side-menu-container'>
                    <SideMenu />
                </div>
                <div className='main-holder-container flex items-center justify-center ml-16 mr-16'>
                    <MainHolder />
                </div>
            </div>
        </>
    )
}
