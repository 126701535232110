import React, { useState, useEffect } from 'react';
import './App.css';
import './colors.css'
import Holder from './organizer/holder/holder';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LoginRegister from './organizer/LoginRegister/loginRegister';
import PasswordReset from './organizer/LoginRegister/PasswordReset';
// import HlsVideoPlayer from './organizer/HlsVideoPlayer/HlsVideoPlayer';
import { ToastContainer } from 'react-toastify';
import EmailVerificationMessage from './organizer/LoginRegister/EmailVerificationMessage';
import VerifyEmail from './organizer/LoginRegister/VerifyEmail';
import { ring } from 'ldrs'
import { useSelector } from 'react-redux';
ring.register();

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authState = useSelector((state)=> state.auth)

  useEffect(() => {
    // const isTokenPresent = !!localStorage.getItem('accessToken');
    // setIsAuth(isTokenPresent);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center gap-x-4 h-[100vh] -mt-10">
        <h1 className="text-xl font-semibold">Loading... </h1>
        <l-ring
          size="35"
          stroke="4"
          bg-opacity="0.1"
          speed="2" 
          color="black" 
        ></l-ring>
      </div>
    );
  }
  
  return (
    <GoogleOAuthProvider clientId='170731699509-0sp6edudkmmcgomgmu6u4ps99naue36q.apps.googleusercontent.com'>
      <div className="main-app">
      <ToastContainer />
        <Router>
          <Routes>
            {authState.isAuthenticated ? <Route path='*' exact element={<Holder />}></Route> : <Route path='*' element={<LoginRegister />}></Route>}
            {/*<Route path='/play' element= {<HlsVideoPlayer />}></Route>*/}
            <Route path="/password-reset/confirm/:uid/:token" element = {<PasswordReset />} />
            <Route path="/emailverification" element = {<EmailVerificationMessage />} />
            <Route path="/email/confirm/:key" element = {<VerifyEmail />} />
          </Routes>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
