import React, { useEffect, useState } from 'react';
import './LoginRegister.css';
import api from "../../axiosConfig";
import { register, updatePassowrd } from './logic';
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from "axios";

import { Eye, EyeSlash } from 'iconsax-react';
import { GoogleFlatColorIcon } from '../create-video/icon';
import { AppleIcon, FacebookIcon, GoogleFlatIcon, TwitterIcon } from './icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { login } from "../redux/actions/authAction";

const LoginRegister = () => {
    const [activeTab, setActiveTab] = useState('login');
    const [loginErrors, setLoginErrors] = useState({});
    const [registerErrors, setRegisterErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState([]);
    const [warning, setWarning] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);
    const [cooldownTimer, setCooldownTimer] = useState(0);
    const [ForgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });
    const [registerData, setRegisterData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
        isTncChecked: false
    });
    const dispatch = useDispatch();
    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const handleRegisterChange = (e) => {
        const { name, value } = e.target;
        if(name === 'isTncChecked'){
            setRegisterData({ ...registerData, [name]: !registerData.isTncChecked });
        }else{
          setRegisterData({ ...registerData, [name]: value });
        }
    };

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (loginData.email === '') {
            setWarning(true);
            setLoginErrors({ email: 'Email is required' });
        } else if (loginData.password === '') {
            setWarning(true);
            setLoginErrors({ password: 'Password is required' });
        } else {
            setWarning(false);
            dispatch(login(loginData.email, loginData.password))
        }
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        const errors = {};
        if (registerData.username === '') {
            errors.username = 'Username is required';
        }
        if (registerData.password === '') {
            errors.password = 'Password is required';
        }
        if (registerData.confirmPassword === '') {
            errors.confirmPassword = 'Confirm Password is required';
        }
        if (registerData.password !== registerData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if(!registerData.isTncChecked){
            errors.tnc = 'Please Accept Terms and conditions'
        }
        if (registerData.email === '') {
            errors.email = 'Email is required';
        } else if (!/^\S+@\S+\.\S+$/.test(registerData.email)) {
            errors.email = 'Email is invalid';
        }
        setRegisterErrors(errors);

        if (Object.keys(errors).length === 0) {
            console.log('Registration successful');
            register(registerData.username, registerData.password, registerData.email, registerData.confirmPassword);
        }
    };

    const GoogleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const res = await axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${codeResponse.access_token}`,
                        Accept: 'application/json'
                    }
                })
            const user = res.data;
            console.log(user);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleEmailSubmit = ()=>{
        if(ForgotPasswordEmail === ''){
            setWarning(true);
            toast.error("Email Can't be empty");
        }else if (!/^\S+@\S+\.\S+$/.test(ForgotPasswordEmail)) {
            setWarning(false);
            toast.error("Email is invalid");
        }else{
            setWarning(false);
            updatePassowrd(ForgotPasswordEmail);
            setIsCooldown(true);
            setCooldownTimer(30);
        }
    }
    const handleForgotPasswordEmailChange = (e)=>{
        setForgotPasswordEmail(e.target.value);
    }

    useEffect(() => {
        let timer;
        if (isCooldown && cooldownTimer > 0) {
            timer = setTimeout(() => {
                setCooldownTimer(cooldownTimer - 1);
            }, 1000);
        } else if (cooldownTimer === 0) {
            setIsCooldown(false);
        }
        return () => clearTimeout(timer);
    }, [isCooldown, cooldownTimer]);

    return (
        <div className="w-full flex">
            <div className='left-container w-3/5 h-screen bg-slate-200 rounded-tr-3xl rounded-br-3xl'></div>
            <div className="form-container w-2/5 flex flex-col items-center justify-center">
                {activeTab === 'login' && (
                    <div className='flex flex-col'>
                        <div className='register-head flex flex-col items-start gap-y-4 mb-12'>
                            <div className='greetings'>
                                <h3 className='text-3xl font-semibold'>Welcome to ChillyPepper 👋</h3>
                            </div>
                            <div className='register-tagline'>
                                <h3 className='text-base font-normal'>Kindly fill in your details to Login</h3>
                            </div>
                        </div>
                        <div className="login-form-container">
                            <form onSubmit={handleLoginSubmit} className='login-Container'>
                                {/* Email */}
                                <div className='username-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Email</h4>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="John Doe"
                                        value={loginData.email}
                                        onChange={handleLoginChange}
                                        className={`p-5 ${loginErrors.email && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                    />
                                    {warning && loginData.email.trim() === "" ? <span className="warning font-medium text-red-600">Email is required</span> : ""}
                                    {/* {loginErrors.email && <h4 className="error normal">{loginErrors.email}</h4>} */}
                                </div>
                                <div className='password-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Password</h4>
                                    <div className="relative flex items-center">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Password"
                                            value={loginData.password}
                                            onChange={handleLoginChange}
                                            className={`p-5 ${loginErrors.password && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-[--secondary-txt] focus:outline-none"
                                        >
                                            {showPassword ? (<EyeSlash size="24" />) : (<Eye size="24" />)}
                                        </button>
                                    </div>
                                    {warning && loginData.password.trim() === "" ? <span className="warning font-medium text-red-600">Password is required</span> : ""}
                                    {/* {loginErrors.password && <h4 className="error normal">{loginErrors.password}</h4>} */}
                                </div>
                                <button className="lr-button p-5 bg-[--accent-1] w-full rounded-md text-white font-medium mb-5" type="submit">Login</button>

                                <div className='switch-to-register w-full flex flex-col items-center justify-center gap-y-8'>
                                    <h3 className={`${activeTab === 'register' ? 'active-tab' : ''} `}>Create An Account, <button className='font-bold text-[--accent-1] underline cursor-pointer' onClick={() => { setActiveTab('register'); setShowPassword(false); }}>Signup</button></h3>
                                    <h3 className={`${activeTab === 'forgot-password' ? 'active-tab' : ''} `}>Don't remember password <button className='font-bold text-[--accent-1] underline cursor-pointer' onClick={() => { setActiveTab('forgot-password'); setShowPassword(false); }}>Forgot password</button></h3>

                                    <span className='text-[--secondary-txt] font-medium hidden'>Or</span>

                                    <div className='social-login-icons flex items-center justify-center gap-x-6 w-full hidden'>
                                        <span> <GoogleFlatIcon /> </span>
                                        <span> <AppleIcon /> </span>
                                        <span> <FacebookIcon /> </span>
                                        <span> <TwitterIcon /> </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {activeTab === 'register' && (
                    <div className='flex flex-col'>
                        <div className='register-head flex flex-col items-start gap-y-4 mb-12'>
                            <div className='greetings'>
                                <h3 className='text-3xl font-semibold'>Welcome to ChillyPepper 👋</h3>
                            </div>
                            <div className='register-tagline'>
                                <h3 className='text-base font-normal'>Kindly fill in your details below to create an account</h3>
                            </div>
                        </div>
                        <div className="register-form-container">
                            <form onSubmit={handleRegisterSubmit} className='login-container flex flex-col'>
                                {/* name */}
                                <div className='fullname-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Username</h4>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Enter your username"
                                        value={registerData.username}
                                        onChange={handleRegisterChange}
                                        className={`p-5 ${loginErrors.password && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                    />
                                    {registerErrors.username && <h4 className="error normal text-red-600">{registerErrors.username}</h4>}
                                </div>

                                {/* email */}
                                <div className='email-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Email</h4>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter your email address"
                                        value={registerData.email}
                                        onChange={handleRegisterChange}
                                        className={`p-5 ${loginErrors.password && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}

                                    />
                                    {registerErrors.email && <h4 className="error normal text-red-600">{registerErrors.email}</h4>}
                                </div>

                                {/* password */}
                                <div className='password-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Password</h4>
                                    <div className='relative'>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Enter your password"
                                            value={registerData.password}
                                            onChange={handleRegisterChange}
                                            className={`p-5 ${loginErrors.password && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-[--secondary-txt] focus:outline-none"
                                        >
                                            {showPassword ? (<EyeSlash size="24" />) : (<Eye size="24" />)}
                                        </button>
                                    </div>
                                    {registerErrors.password && <h4 className="error normal text-red-600">{registerErrors.password}</h4>}
                                </div>

                                {/* confirm password */}
                                <div className='confirm-password-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Confirm Password</h4>
                                    <div className='relative'>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            placeholder="Confirm your password"
                                            value={registerData.confirmPassword}
                                            onChange={handleRegisterChange}
                                            className={`p-5 ${loginErrors.password && 'inputBox-lr'} border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-[--secondary-txt] focus:outline-none"
                                        >
                                            {showPassword ? (<EyeSlash size="24" />) : (<Eye size="24" />)}
                                        </button>
                                    </div>
                                    {registerErrors.confirmPassword && <h4 className="error normal text-red-600">{registerErrors.confirmPassword}</h4>}
                                </div>

                                {/* agree to terms and conditions */}
                                <div className='tnc-field-container w-full'>
                                    <div className='flex items-center justify-start gap-x-2'>
                                        <input
                                            type="checkbox"
                                            name="isTncChecked"
                                            onChange={handleRegisterChange}
                                            checked={registerData.isTncChecked}
                                            className={`${loginErrors.password && 'inputBox-lr'} outline-none inline`}
                                        />
                                        <span className='text-base font-medium text-[--secondary-txt]'>I agree to terms & conditions</span>
                                    </div>
                                    {registerErrors.tnc && <h4 className="error normal text-red-600">{registerErrors.tnc}</h4>}
                                </div>

                                <div className='flex flex-col items-center justify-center w-full pt-6 gap-y-5'>
                                    <button className="lr-button p-5 bg-[--accent-1] w-full rounded-md text-white font-medium" type="submit">Register Account</button>
                                    <span className='text-xs'>Or</span>
                                    <button className="lr-button p-5 bg-[--accent-2] w-full rounded-md text-white font-medium flex items-center justify-center gap-x-2" onClick={() => GoogleLogin()}><GoogleFlatColorIcon /> <span>Register with Google</span></button>
                                </div>
                            </form>
                        </div>
                        <div className='swith-to-login pt-5'>
                            <h3 className={`text-[--secondary-txt] ${activeTab === 'login' ? 'active-tab' : ''}`}>Already have an account? <button className='font-bold text-[--accent-1] underline cursor-pointer' onClick={() => { setActiveTab('login'); setShowPassword(false) }}>Log in</button></h3>
                        </div>
                    </div>
                )}
                {activeTab === 'forgot-password' && (
                    <div className='flex flex-col'>
                        <div className='register-head flex flex-col items-start gap-y-4 mb-12'>
                            <div className='greetings'>
                                <h3 className='text-3xl font-semibold'>Welcome to ChillyPepper 👋</h3>
                            </div>
                            <div className='register-tagline'>
                                <h3 className='text-base font-normal'>Kindly fill your email id</h3>
                            </div>
                        </div>
                        <div className="login-form-container">
                                {/* Email */}
                                <div className='username-field-container pb-5'>
                                    <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Email</h4>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Johndoe@gmail.com"
                                        value={ForgotPasswordEmail}
                                        onChange={handleForgotPasswordEmailChange}
                                        className={`p-5 'inputBox-lr' border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                                    />
                                    {warning && ForgotPasswordEmail.trim() === "" ? <span className="warning font-medium text-red-600">Email is required</span> : ""}
                                </div>
                                {/* <button className="lr-button p-5 bg-[--accent-1] w-full rounded-md text-white font-medium mb-5" type="submit" onClick={handleEmailSubmit}>Send Email</button> */}
                                <button 
                                    className="lr-button p-5 bg-[--accent-1] w-full rounded-md text-white font-medium mb-5" 
                                    type="submit" 
                                    onClick={handleEmailSubmit}
                                    disabled={isCooldown}
                                >
                                {isCooldown ? `Resend Email after ${cooldownTimer}s` : 'Send Email'}</button>
                                <div className='swith-to-login pt-5'>
                                    <h3 className={`text-[--secondary-txt] ${activeTab === 'forgot-password' ? 'active-tab' : ''}`}>Already have an account? <button className='font-bold text-[--accent-1] underline cursor-pointer' onClick={() => { setActiveTab('login'); setShowPassword(false) }}>Log in</button></h3>
                                </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginRegister;
