import React, { useState, useEffect, useCallback } from "react";
import "./createcollection.css";
import Select from "react-select";
import api from "../../axiosConfig";
import SaveCollection, { putCollection } from "./logic";
import { useNavigate, useParams } from "react-router-dom";
import { CloseSquare } from "iconsax-react";

function CheckBox(onCheck) {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    console.log("change");
  }, [isActive]);
  return (
    <div
      className="switchButton"
      style={isActive ? { backgroundColor: "rgb(0, 223, 0)" } : {}}
    >
      <div className={isActive ? "checkactive" : "checkinactive"}></div>

      <input
        type="checkbox"
        className="switchCheck"
        onChange={(e) => {
          setIsActive(e.target.checked);
        }}
      />
    </div>
  );
}

function InputField(props) {
  return (
    <div className="inputcontainer">
      <div className="input-label">
        <h4 className="bold">{props.label}</h4>{" "}
        {props.enableButton ? (
          <div className="titlebuttonsection">
            <h4 className="normal">{props.enableTitle}</h4>
            <CheckBox></CheckBox>
          </div>
        ) : (
          ""
        )}{" "}
      </div>
      {props.enableInput ? (
        <input
          value={props.value}
          type={props.type ? props.type : "text"}
          placeholder={props.placeholder}
          className="inputBox"
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
      ) : (
        ""
      )}
      {props.children}
    </div>
  );
}

function SelectVideo(props) {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const getData = useCallback(async (url = "/videos/") => {
    setLoading(true);
    try {
      console.log("getData running...");
      // const startTime = Date.now();
      const resp = await api.get(url);
      // const endTime = Date.now();
      // const duration = endTime - startTime; // duration is in millisecs

      // if (duration >= 2000) {
      //   // if api-call takes 2+ secs, simulating a delay:
      //   await new Promise((resolve) => setTimeout(resolve, 2000));
      // }

      const data = resp.data.results;
      const nextUrl = resp.data.next;
      console.log("NextURLLL ->", nextUrl);

      setListData(prevData => [...prevData, ...data]);

      setNextUrl(nextUrl || null);
    } catch (err) {
      console.error("Error getting data:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (listData.length === 0) getData();
  }, [getData, listData.length]);

  const handleMenuScroll = async event => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const bottom = scrollTop + clientHeight >= scrollHeight - 50;
  
    if (bottom && nextUrl && !loading) {
      await getData(nextUrl);
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedVideos(selectedOptions);
    props.setSelectedVideos(selectedOptions);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ced4da",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 0 0.04rem #000" : null,
      "&:hover": {
        borderColor: "var(--accent-1)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : null,
      color: state.isSelected ? "#ffffff" : null,
      "&:hover": {
        backgroundColor: "var(--accent-1)",
        color: "#ffffff",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "var(--accent-1)",
      color: "#ffffff",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ffffff",
      ":hover": {
        backgroundColor: "var(--accent-1)",
        color: "#ffffff",
      },
    }),
  };

  const options = loading ? [...listData, {id: "loading", title: "Loading..."}] : listData;
  
  return (
    <div className="select-video w-full mt-2">
      <div className="selecthead text-base font-medium">Select Video</div>
      <div className="flex items-center gap-x-2">
        <div id="select-container" className="select-container">
          <Select
            onMenuScrollToBottom={handleMenuScroll}
            className="react-select-container w-full"
            classNamePrefix="react-select"
            options={options}
            isMulti
            value={props.selected}
            onChange={handleChange}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            styles={customStyles}
            placeholder="Select videos"
          />
        </div>
        <button
          className="button-cont bg-[--accent-1] text-white px-3 py-2 rounded-lg"
          onClick={() => {
            console.log("listData -->", listData);
            props.closeModal();
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default function CreateCollection() {
  const id = useParams();
  const [tab, setTab] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [called, setCalled] = useState(false);
  const navigate = useNavigate();
  const removeVideo = (videoId) => {
    setSelectedVideos((prevSelectedVideos) =>
      prevSelectedVideos.filter((video) => video.id !== videoId)
    );
  };
  const onSave = () => {
    let errmsg = "Please fill the following Fields - ";
    if (title === "") {
      errmsg += "Title, ";
    }
    if (errmsg === "Please fill the following Fields - ") {
      if ("id" in id) {
        putCollection(title, selectedVideos, navigate, id["id"]);
      } else {
        SaveCollection(title, selectedVideos, navigate);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/collections/" + id["id"] + "/");
        setTitle(response.data.name);
        setCalled(true);

        const vidresponse = await api.get("/videos/");
        const videoIds = response.data.videos;

        const filteredVideos = vidresponse.data.results.filter((video) =>
          videoIds.includes(video.id)
        );

        setSelectedVideos(filteredVideos);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    if ("id" in id && !called) {
      fetchData();
    }
  }, [called, id, title]);

  return (
    <>
      {/* create collection modal */}
      <div className="create-collection-modal w-[40%] flex flex-col items-center justify-center mt-16">
        <div className="create-video-head w-full">
          <div className="head-title flex items-center justify-between">
            <h1 className="bold capt text-2xl">
              {title === "" ? "Untitled Collection" : title}
            </h1>
            <button
              className="save-button bg-[--accent-1] text-white px-3 py-2 rounded-2xl font-semibold text-xl"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div className="create-collection-body w-full mt-2">
          <div className="card-container">
            <div className="formcard">
              <input
                type="text"
                placeholder="Collection Name"
                className="border-2 border-[--app-border] p-5 font-medium text-[--secondary-txt] rounded-md outline-none w-full"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {/* <InputField enableInput={true} value={title} setValue={setTitle} placeholder="Enter Collection title" label="Collection Title*" enableButton={false}></InputField> */}
            </div>
            <div className="formcard formcard-collection mt-2 ">
              <div className="head">
                <h4 className="text-xl font-bold">Videos</h4>
              </div>
              <div className="body">
                <div className="addVideoSection">
                  {tab ? (
                    <SelectVideo
                      closeModal={() => {
                        setTab(false);
                      }}
                      selected={selectedVideos}
                      setSelectedVideos={setSelectedVideos}
                      onselect={setSelectedVideos}
                    ></SelectVideo>
                  ) : (
                    <>
                      {selectedVideos.length > 0 ? (
                        <div className="selected-videos">
                          <div className="selected-video">
                            <strong>Selected Videos</strong>
                          </div>
                          <div className="flex items-center gap-2">
                            {selectedVideos.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="selected-video flex items-center gap-x-1 border border-[--app-border] rounded-md p-1"
                                >
                                  <p className="text-base font-medium">
                                    {item.title}
                                  </p>
                                  <button onClick={() => removeVideo(item.id)}>
                                    <b>
                                      {" "}
                                      <CloseSquare size={"1.25rem"} />{" "}
                                    </b>
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="button-container mt-2">
                        <h3
                          className="select-videos-button bg-[--accent-1] text-white px-3 py-2 rounded-2xl font-semibold text-xl"
                          onClick={() => {
                            setTab(true);
                          }}
                        >
                          Select videos
                        </h3>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}