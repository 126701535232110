import api from "../../../axiosConfig"
export const login = (email, password) => async (dispatch) => {
    try {
        const response = await api.post('/auth/login/', { email, password });
        dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
                accessToken: response.data.access,
                refreshToken: response.data.refresh,
                user: response.data.user,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Login error:', error);
    }
};
export const logout = () => async (dispatch) => {
    try {
        const logoutResponse = await api.post('/auth/logout/');
        if (logoutResponse.status === 200) {
            console.log('Successfully logged out');
        }
        dispatch({
            type: "LOGOUT"
        });
    } catch (error) {
        console.error('Logout error:', error);
    }
};

export const updateAccessToken = (newAccessToken) => ({
    type: "UPDATE_ACCESS_TOKEN",
    payload: { accessToken: newAccessToken },
});