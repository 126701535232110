import React, { useState, useEffect, useCallback, useRef } from "react";
import "./createvideo.css"
import { IoClose } from "./icon.js"
import './modal.css'
import { patchCreateVideo, putCreateVideo, saveCreateVideo } from "./logic.js";
import { getPreSignedUrl, uploadImageFile, uploadImageFromUrl, uploadVideoFile } from "./s3-upload"
import { useNavigate, useParams } from "react-router-dom";
import api from "../../axiosConfig.js";
import { CloseSquare } from 'iconsax-react'
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';

const MAX_UPLOAD_FILE_SIZE = 100; // allow max 100MB file upload

const FileUpload = ({ onUpload, setVidUrl, modalClose, setVideoUrl }) => {
    const [highlight, setHighlight] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploadStart, setUploadStart] = useState(false);
    const [fileSizeWarning, setFileSizeWarning] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {

    }, [progress])
    const handleDragOver = (e) => {
        e.preventDefault();
        setHighlight(true);
    };

    const handleDragLeave = () => {
        setHighlight(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setHighlight(false);
        const file = e.dataTransfer.files[0];

        if (file && file.size <= MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
            setSelectedFile(file);
            setFileSizeWarning(false);
        } else {
            console.error("File size exceeds the limit");
            setFileSizeWarning(true);
        }
    };

    const handleInputChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm'];

            if (allowedExtensions.includes(fileExtension)) {
                if (file.size <= MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
                    setSelectedFile(file);
                    setFileSizeWarning(false);
                } else {
                    console.error("File size exceeds the limit");
                    setFileSizeWarning(true);
                }
            } else {
                console.error("Invalid file type. Please select a video file.");
                toast("Invalid file type. Please select a video file.");
                setSelectedFile(null);
            }
        }
    };


    return (
        <>
            {!selectedFile && (
                <div
                    className={`file-upload ${highlight ? 'highlight' : ''} relative`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => inputRef.current.click()}
                    role="presentation"
                >
                    <input
                        type="file"
                        ref={inputRef}
                        className="file-input"
                        onChange={handleInputChange}
                        multiple
                        accept="video/*"
                    />
                    <h3 className="text-2xl font-semibold">Drag Video Files or Click Below</h3>
                    <h4 className='text-xl font-medium'>For best results use an MP4 less than 100MB sized to 720px x 1280px</h4>
                    <button className="mt-6 px-3 py-2 text-2xl font-semibold bg-[--accent-1] text-white rounded-lg">Browse</button>
                    <span className="absolute bottom-2 left-50 right-50">
                        {fileSizeWarning ? <h3 className="text-base font-semibold text-red-500">File size exceeds the limit</h3> : ""}
                    </span>
                </div>
            )}
            {progress !== 100 ? uploadStart ? (
                <div className='upload-progress-container flex flex-col items-center justify-center'>
                    <h4 className="normal">Upload in progress. Do not close the window</h4>
                    <progress value={progress} max="100" className="progress-bar"></progress>
                    <h4 >{progress + "% "}</h4>
                </div>
            )
                :
                selectedFile && (
                    <div className="file-info">
                        <p>Selected File: {selectedFile.name}</p>
                        <div><button className="modal-cont-button px-3 py-2 bg-[--accent-1] text-white font-semibold rounded-2xl" onClick={() => setSelectedFile(null)}>Change File</button>
                            <button className="modal-cont-button px-3 py-2 bg-[--accent-1] text-white font-semibold rounded-2xl" onClick={() => {
                                onUpload(selectedFile, setProgress, setVidUrl, setVideoUrl)
                                setUploadStart(true)
                            }}>Continue</button></div>
                    </div>) :
                <div className="file-info">
                    <h4>Upload Complete</h4>
                    <button className="modal-cont-button px-3 py-2 text-white bg-[--accent-1] rounded-2xl font-semibold" onClick={() => {
                        modalClose(false)
                    }}>Done</button>
                </div>
            }
        </>
    );
};

const Modal = (props) => {


    const toggleModal = () => {
        props.setIsOpen(!props.isOpen);
    };

    return (
        <div>
            {props.isOpen && (
                <div className="modal-overlay ">
                    <div style={props.style} className={(props.className ? " " + props.className : "") + " modal-content"}>
                        <button className="close-btn" onClick={toggleModal}><IoClose></IoClose></button>
                        {props.children}
                    </div>
                </div>
            )}
        </div>
    );
};


function CheckBox({ onCheck, state, setCheckboxValue }) {

    return (
        <div className="switch-button">
            <input
                type="checkbox"
                className="switch-check appearance-none w-3 h-3 bg-gray-300 border border-[--app-border] checked:bg-[--accent-1] checked:border-transparent focus:outline-none cursor-pointer"
                onChange={(e) => {
                    setCheckboxValue(e.target.checked);
                }}
                checked={state}
            />
        </div>
    );
}

function InputField(props) {
    return (
        <div className="inputcontainer">
            <div className="input-label flex items-center justify-between">
                <h4 className="font-bold">{props.label}</h4>
                {props.enableButton &&
                    <div className="title-button-section flex items-center gap-x-4">
                        <h4 className="normal">{props.enableTitle}</h4>
                        <CheckBox onCheck={props.onCheck} state={props.state} setCheckboxValue={props.setCheckboxValue}></CheckBox>
                    </div>}
            </div>

            {props.enableInput && (
                <input value={props.value} type={props.type ? props.type : "text"} placeholder={props.placeholder} className="input-box p-5 border border-[--app-border] text-[--secondary-txt] outline-none rounded-md w-full" onChange={(e) => {
                    props.setValue(e.target.value)
                }} />
            )}
            {props.children}
        </div>
    )
}

const Chip = (props) => {
    function removeFromProductList() {
        let updatedList = [...props.productList];
        updatedList.splice(props.index, 1);
        props.setProductList(updatedList);

        if (props.idData["*"].includes('editvideo/')) {
            patchCreateVideo(updatedList, props.productID);
        }
    }
    return (
        <div className="chip border border-[--app-border] rounded-md flex items-center gap-x-2 p-1 w-fit">
            <div className="chip-body flex flex-col items-start gap-y-1 justify-around">
                <h4 className="chip-title text-[--secondary-txt] text-base font-medium">{props.title}</h4>
                {props.price && (
                    <h3 className="chip-price text-[--secondary-txt] text-base font-medium">Price: {props.price} </h3>)
                }
            </div>
            <button className="chip-delete"
                onClick={removeFromProductList}> <CloseSquare size={'1.75rem'} color="var(--secondary-txt)" /> </button>
        </div>
    )
}

const AddProductModal = props => {
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState("")
    const [link, setLink] = useState("")
    const [image, setImage] = useState("")
    const [progress, setProgress] = useState(0)
    const [imageUrl, setImageUrl] = useState("");
    const [imageFromUrl, setImageFromUrl] = useState(false);
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        let timeoutId;
        const fetchMetadata = async () => {
            try {
                const url = link.startsWith('http') ? link : `http://${link}`;
                const response = await api.post("/fetch-metadata/", {
                    url: url
                })
                let imageUrl = response.data.imageUrl;
                let title = response.data.title;
                setImage(response.data.imageData);
                setTitle(title);
                setImageUrl(imageUrl);
                setImageFromUrl(true);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        if (link?.trim() !== '') {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                fetchMetadata();
            }, 1000);
        }
        return () => clearTimeout(timeoutId);
    }, [link]);

    const validateFields = () => {
        if (link.trim() === "" || title.trim() === "" || price.trim() === "" || !image) {
            setWarning(true);
            return false;
        }
        setWarning(false);
        return true;
    };


    return (
        <Modal className="add-product-modal" isOpen={props.addProductModel} setIsOpen={props.setAddProductModel} style={{ overlay: { background: 'white' }, height: "auto" }}>
            <h3 className="create-video-modal-head text-2xl font-bold mb-2">
                Add Product
            </h3>
            <div className="modal-body">
                <InputField enableInput={true} value={link} setValue={setLink} placeholder="Product Link" label="Product Link" enableButton={false}></InputField>
                {warning && link.trim() === "" ? <span className="warning text-sm text-red-600 block">Product Link is required</span> : ""}
                <InputField enableInput={true} value={title} setValue={setTitle} placeholder="Enter title" label="Product Title*" enableButton={false}></InputField>
                {warning && title.trim() === "" ? <span className="warning text-sm text-red-600 block">Video title is required</span> : ""}
                <InputField enableInput={true} type="number" value={price} setValue={setPrice} placeholder="Price" label="Price" enableButton={false}></InputField>
                {warning && price.trim() === "" ? <span className="warning text-sm text-red-600 block">Product price is required</span> : ""}
                <InputField enableInput={false} label="Product Image" enableButton={false} >
                    <div className="inputBox image">
                        {/* {imageUrl ? <img src={imageUrl}></img> : (
                            <Image size={'1.75rem'} />
                        )} */}

                        <div className="my-2 flex items-center justify-start gap-x-4">
                            <div>
                                <label htmlFor="Upload" className="border border-[--app-border] px-2 py-1 rounded-md">Upload an image</label>
                                <input
                                    id="Upload" // Adding id attribute
                                    accept="image/*"
                                    type="file"
                                    className="Upload hidden"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        const fileSizeInMB = file?.size / 1024 / 1024 || 0;
                                        if (fileSizeInMB > 1) {
                                            alert("The file size exceeds 1 MB. Please upload a smaller image.");
                                            return;
                                        }
                                        setImage(e.target.files[0]);
                                        const imageUrl = URL.createObjectURL(e.target.files[0]);
                                        setImageUrl(imageUrl);
                                        setImageFromUrl(false);
                                    }}
                                ></input>
                            </div>

                            <div>
                                {progress === 0 ? <h4 className="font-medium">{image === "" ? "Product Image" : image?.name}</h4> :
                                    progress !== 100 ? (
                                        <>
                                            <h4 className="font-bold">Uploading image...  </h4>
                                            <progress progress={progress} max="100" className="progress-bar">
                                            </progress>
                                        </>
                                    )
                                        : <h4 className="font-bold">Image Uploaded</h4>}
                            </div>
                        </div>



                    </div>
                </InputField>
                {warning && !image ? <span className="warning text-sm text-red-600 block">Product image is required</span> : ""}
                <div className="flex items-center justify-center">
                    <button className="modal-cont-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-bold" onClick={() => {
                        if (!validateFields()) return;
                        if (typeof image === "string") {
                            props.setProductList([...props.productList, {
                                title: title,
                                price: price,
                                link: link,
                                image: image
                            }])
                            setImage("")
                            setTitle("")
                            setPrice("")
                            setProgress(0)
                            setLink("")
                            props.setAddProductModel(false)
                        }
                        else {
                            if (imageFromUrl) {
                                uploadImageFromUrl(image, imageUrl, setProgress, setImage);
                            } else {
                                uploadImageFile(image, setProgress, setImage);
                            }
                        }

                    }}

                    >{typeof image === "string" ? "Add Product" : "Confirm Upload"}</button>
                </div>
            </div>
        </Modal>)
}
export default function Createvideo() {
    const id = useParams()
    const [getdata, setGetData] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [addProductModel, setAddProductModel] = useState(false);
    const [tab, setTab] = useState(0);
    const [title, setTitle] = useState("");
    const [videoType, setVideoType] = useState(1);
    const [description, setDescription] = useState("");
    const [coverimg, setCoverimg] = useState("");
    const [vidUrl, setVidUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [confirmChange, setConfirmChange] = useState(false);
    const [productList, setProductList] = useState([]);
    const [coverprogress, setCoverProgress] = useState(0)
    const [warning, setWarning] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [isShoppable, setIsShoppable] = useState(false);
    const [isTitle, setIsTitle] = useState(false);
    const [duration, setDuration] = useState(0);
    
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextUrl, setNextUrl] = useState(null);
    const [vidDuration, setVidDuration] = useState(0);

    const navigate = useNavigate()

    const onSave = () => {
        // console.log("Here the vidUrl is -->", vidUrl);
        // console.log("videoUrl =", videoUrl);
        // console.log("duration =", duration);
        // console.log("vidDuration =", vidDuration);
        if (title === "" || vidUrl === "" || description === "" || coverimg === "" || productList.length === 0 || vidDuration === 0) {
            console.log("Setting warning true...");
            setWarning(true);
        } 
        if (id["*"] === "createvideo") {
            if (videoType == 1) {
                // console.log('running 1...');
                const regex = /([^/]+)\?/;
                const trimVidURL = videoUrl.match(regex);
                const trimmedVidFilePrefix = trimVidURL[1];
                saveCreateVideo(title, description, trimmedVidFilePrefix, coverimg, productList, isShoppable, isTitle, videoType, vidDuration, navigate, videoUrl)
                .then(status => {
                    if (status === 400) {
                        setWarning(true);
                    }
                });
            } else if (videoType == 2) {
                // console.log('running 2...');
                setVidDuration(6000);
                saveCreateVideo(title, description, vidUrl, coverimg, productList, isShoppable, isTitle, videoType, vidDuration, navigate, videoUrl)
                    .then(status => {
                        if (status === 400) {
                            setWarning(true);
                        }
                    });
            } else if (videoType === 3) {
                // console.log('running 3...');
                saveCreateVideo(title, description, vidUrl, coverimg, productList, isShoppable, isTitle, videoType, vidDuration, navigate, videoUrl)
                    .then(status => {
                        if (status === 400) {
                            setWarning(true);
                        }
                    });
                }
        }

        if (id["*"].includes('editvideo/')) {
            const regex = /([^/]+)\?/;
            const trimVidURL = vidUrl.match(regex);
            const trimmedVidFilePrefix = trimVidURL[1];
            putCreateVideo(title, description, trimmedVidFilePrefix, coverimg, productList, isShoppable, isTitle, videoType, vidDuration, navigate, id["id"]);
        }
    }

    const handleImageUpload = (files) => {
        const selectedImage = files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setImagePreview(reader.result);
        };

        if (selectedImage) {
            reader.readAsDataURL(selectedImage);
        }
    };
    const fetchLibraryVideos = useCallback (async (url = "/video-library/") => {
        setLoading(true);
        try {
          const resp = await api.get(url);
          const data = resp.data.results;
          const nextUrl = resp.data.next;
          
          const updatedUrlVideos = await Promise.all(data.map(async (video) => {
              const url = await getPreSignedUrl(video.video);
              video.url = url.url;
              return video;
          }));

          setListData(prevVideos => [...prevVideos, ...updatedUrlVideos]);
          setNextUrl(nextUrl || null);
        } catch (error) {
          console.error('Error fetching videos:', error);
        } finally {
            setLoading(false);
        }
      }, []);

      useEffect(() => {
        if (listData.length === 0) fetchLibraryVideos();
      }, [fetchLibraryVideos, listData.length]);
    
      const handleScroll = async (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const bottom = scrollTop + clientHeight >= scrollHeight - 50;
      
        if (bottom && nextUrl && !loading) {
          await fetchLibraryVideos(nextUrl);
        }
      };
      
      useEffect(() => {
        const scrollContainer = document.getElementById("scroll-container");
      
        if (scrollContainer) {
          scrollContainer.addEventListener("scroll", handleScroll);
        }
      
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener("scroll", handleScroll);
          }
        };
      }, [modalIsOpen, nextUrl, loading, fetchLibraryVideos]);
    
    const selectingVideoFromLibrary = (video) =>{
        setVidUrl(video.video);
        console.log("video.video =", video.video);
        setVideoUrl(video.url);
        setTitle(video.title);
        setVideoType(video.video_type);
        setModalIsOpen(false);
    }

    const handleVidDuration = (duration) => {
        console.log("Setting video duration to:", duration);
        setVidDuration(duration);
    };
    
    useEffect(() => {
        if (id["*"] !== "createvideo" && getdata === "") {
            api.get("/videos/" + id["id"] + "/").then(function (response) {
                setGetData(response.data)
            }).catch(function (error) {
                console.log(error)
            })
        }

        // fetch uploaded image on edit page
        if (vidUrl !== "") {
            try {
                if (vidUrl.includes('nuclx-media')) {
                    console.log("Video URL already exists");
                } else if (vidUrl.includes("nuclex-converted-media")) {
                    console.log("requesting presigned vid url");
                    // console.log("vidUrl ---->", vidUrl);
                    api.get(`/auth/get-pre-signed-url/${vidUrl}`).then(function (response) {
                        // console.log("data->", response.data.url);
                        setVidUrl(response.data.url);
                    })
                } else if (vidUrl.includes("instagram")) {
                    console.log("instagram vidUrl detected...");
                    // setVidUrl(vidUrl);
                    setVideoType(2);
                } else if (vidUrl.includes("youtube")) {
                    console.log("youtube vidUrl detected...");
                    // console.log("vidUrl ==", vidUrl);
                    // setVidUrl(vidUrl);
                    setVideoType(3);
                }
            } catch (err) {
                console.error("Error found:", err);
            }
        }

        if (getdata !== "" && getdata !== "used") {
            console.log("get data from getdata statement:", getdata)
            setTitle(getdata.title)
            setDescription(getdata.description)
            // setVidUrl(getdata.video);
            setVidUrl(getdata.video_url)
            setProductList(getdata.products)
            setCoverimg(getdata.coverImage)
            setIsShoppable(getdata.is_shoppable)
            setIsTitle(getdata.is_title)
            setDuration(getdata.duration)
            setGetData("used")
        }

        if (confirmChange) {
            setTimeout(() => {
                setConfirmChange(false);
            }, 10000);
        }
    }, [confirmChange, setAddProductModel, getdata, id, vidUrl]);

    function onDiscard() {
        navigate('/videomanager')
    }

    let content;

    return (<>
        <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
            <div className="modal-container h-full">
                <div className="create-video-modal-head">
                    <h3 className="text-3xl font-bold text-center">Add A Video</h3>
                </div>
                <div className="modal-body h-full">
                    <div className="modal-head flex items-center justify-center gap-x-4 py-2 text-xl font-medium">
                        {/* TODO: apply library logic */}
                        <button id="library-video-container" 
                        onClick={() => {
                            setTab(0);
                            // fetchLibraryVideos();
                        }} className={tab === 0 ? "activeTab underline" : "nonActiveTab"}>Library</button>
                        {/* <button onClick={() => {
                            setTab(1)
                        }} className={tab === 1 ? "activeTab underline" : "nonActiveTab"}>Upload</button> */}
                        <button onClick={() => {
                            setTab(1)
                        }} className={tab === 1 ? "activeTab underline" : "nonActiveTab"}>Link</button>
                    </div>
                    <div className="body h-fit mt-6">
                        {
                            tab === 0 ? (
                                <div id="scroll-container" className="library-tab h-72 overflow-y-auto">
                                    <ul className="space-y-4">
                                        {listData.map(video => (
                                            <li key={video.id} className="video-item flex gap-4">
                                                <video controls className="w-1/3 rounded-md border border-gray-300">
                                                    <source src={video.url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className="text-xl font-semibold self-center">
                                                    {/* <h4 className="text-xl font-semibold self-center">{video.title}</h4> */}
                                                    <button className="text-xl px-5 py-1 rounded-2xl bg-[--accent-1] text-white border border-[--accent-1] my-3"
                                                    onClick={()=>{
                                                        selectingVideoFromLibrary(video);
                                                    }}>Add
                                                    </button>
                                                </div>
                                            </li>

                                        ))}
                                        {loading ? (<p className="text-xl font-semibold my-10 mx-10">Loading...</p>) : (<></>)}
                                        {!nextUrl && <p className="mt-4 text-gray-600">No more videos</p>}
                                    </ul>
                                </div>
                            ) 
                            // : tab === 1 ? (
                            //     <FileUpload onUpload={uploadVideoFile} setVidUrl={setVidUrl} modalClose={setModalIsOpen} setVideoUrl={setVideoUrl} />
                            // ) 
                            : (
                                <div className="modal-link-input rounded-xl h-full bg-gray-100 flex flex-col items-center justify-center gap-y-5 px-12 py-4">
                                    <h4 className="text-2xl font-semibold">Add your own link for a video</h4>
                                    <h4 className="text-xl font-medium">Paste a link to a video on Dropbox or a CDN to use for your Interactive Video. Make sure the video link is public.</h4>
                                    <input type="text" value={vidUrl} onChange={(e) => { setVidUrl(e.target.value) }} placeholder="Paste Url" className="modal-input border border-[--app-border] p-5 w-full rounded-xl" />
                                    <button
                                        className="modal-continue-button bg-[--accent-1] text-white px-3 py-2 rounded-2xl font-bold text-2xl"
                                        onClick={() => {
                                            if (vidUrl !== "") {
                                                setModalIsOpen(false);
                                            };
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal>

        {/* add product modal */}
        {setAddProductModel ? <AddProductModal setProductList={setProductList} productList={productList} addProductModel={addProductModel} setAddProductModel={setAddProductModel}></AddProductModal>
            : ""}
        <div className="w-full mt-16">
            <div className="create-video-head flex items-center justify-between">
                <div className="head-title">
                    <h1 className="text-4xl font-bold">Add Product</h1>
                </div>
                <div className='left-buttons flex items-center justify-evenly gap-x-4'>
                    <div className="discard-button">
                        <button className="text-xl font-medium px-3 py-2 rounded-2xl text-[--secondary-txt] border border-[--app-border]" onClick={onDiscard}>Discard</button>
                    </div>
                    <div className="discard-button">
                        <button className="text-xl font-bold px-3 py-2 rounded-2xl bg-[--accent-1] text-white border border-[--accent-1]" onClick={() => onSave()}>Save</button>
                    </div>
                </div>
            </div>
            <div className="create-video-body h-full mt-12">
                <div className="card-container h-[60vh] flex items-center justify-between gap-x-4">
                    <div className="form-card w-2/6 h-full border border-[--app-border] rounded-3xl p-4 pb-6">
                        <div className="card-body h-full flex items-center justify-center">
                            {vidUrl === "" ? (
                                <div className="add-video-section flex flex-col items-center justify-center gap-y-4">
                                    <h3 className="text-2xl font-bold">Add A Video</h3>
                                    <h4 className="text-base font-medium">
                                        For best results use files less than 100MB
                                    </h4>
                                    <button className="add-video-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white text-xl font-bold" onClick={() => {
                                        setModalIsOpen(true)
                                        // fetchLibraryVideos();
                                    }}>Add</button>
                                    {warning && videoUrl.trim() === "" ? <span className="warning font-medium text-red-600">Video is required</span> : ""}
                                </div>
                            )
                                :
                                (
                                    <div className="video-upload-section flex flex-col items-center justify-between gap-y-2 h-full w-full">
                                        <h4 className="text-lg font-semibold text-center">Uploaded Video</h4>
                                        <div className="relative w-full h-full" style={{ maxHeight: '100%', maxWidth: '60%' }}>
                                            <div className="flex w-50 h-[100%] aspect-w-9 aspect-h-16">
                                                {/* {vidUrl.includes("instagram") ? (<video height="800" width="400" controls className="flex-1 border-5 border-blue-500"><source src={vidUrl} type="video/mp4" onClick={() => {console.log("Hii...", vidUrl)}}/></video>) 
                                                    : (  */}
                                                    <ReactPlayer 
                                                        controls={true}
                                                        className="flex-1"
                                                        width="100%"
                                                        height="100%"
                                                        url={vidUrl} 
                                                        onDuration={handleVidDuration}
                                                        onClick={() => {
                                                            console.log("vidUrl -->", vidUrl);
                                                            console.log("videoDuration -->", vidDuration);
                                                        }}
                                                        style={{ backgroundColor: 'black' }}
                                                    />
                                                {/* )} */}
                                            </div>
                                        </div>
                                        <button className={`${confirmChange ? "changeVideoButton activeconfirm" : "changeVideoButton"} px-3 py-2 text-lg font-semibold text-[--secondary-txt] border border-[--app-border] rounded-2xl`} onClick={() => {
                                            if (!confirmChange) {
                                                setConfirmChange(true);
                                                console.log(videoUrl)
                                            }
                                            else {
                                                setVidUrl("");
                                                setVideoUrl("");
                                                setConfirmChange(false);
                                            }
                                        }}>{confirmChange ? "Confirm" : "Change"}
                                        </button>
                                    </div>

                                )
                            }
                        </div>
                    </div>

                    <div className="form-card w-4/6 h-full border border-[--app-border] rounded-3xl p-4">

                        <div className="form-fields-container flex items-start gap-x-6 h-full relative">
                            <div className="left-content w-1/2">
                                {/* video title */}
                                <InputField enableInput={true} value={title} setValue={setTitle} placeholder="Enter title" label="Video Title*" enableButton={true} enableTitle="Display title" state={isTitle} setCheckboxValue={setIsTitle}></InputField>
                                {warning && title.trim() === "" ? <span className="warning font-medium text-red-600">Title is required</span> : ""}

                                {/* description */}
                                <InputField enableInput={true} label="Description" value={description} setValue={setDescription} placeholder="Enter description" enableButton={false}>
                                    {/* <textarea value={description} onChange={(e) => { setDescription(e.target.value) }} className="inputBox description" placeholder="Description"></textarea> */}
                                </InputField>
                                {warning && description.trim() === "" ? <span className="warning font-medium text-red-600">Title is required</span> : ""}

                                {/* cover image */}
                                <InputField enableInput={false} label="Cover Image" enableButton={false} >
                                    <div className="input-box image border border-[--app-border] p-5 rounded-md flex items-center justify-between gap-x-4 flex-wrap">
                                        <div>
                                            <label htmlFor="imageInput" className="custom-upload underline">
                                                Click to add image
                                            </label>
                                            <input
                                                id="imageInput"
                                                accept="image/*"
                                                type="file"
                                                className="Upload hidden"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    const fileSizeInMB = file?.size / 1024 / 1024;
                                                    if (fileSizeInMB > 1) {
                                                        toast.warning("The file size exceeds 1 MB. Please upload a smaller image.");
                                                        return;
                                                    }
                                                    setCoverimg(e.target.files[0]);
                                                    uploadImageFile(e.target.files[0], setCoverProgress, setCoverimg);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {coverprogress === 0 ? (
                                                <h4>{coverimg === "" ? "Product Image" : coverimg.name && <span>{coverimg.name}</span>}</h4>
                                            ) : coverprogress !== 100 ? (
                                                <>
                                                    <h4 className="bold">Uploading image... </h4>
                                                    <progress progress={coverprogress} max="100" className="progress-bar"></progress>
                                                </>
                                            ) : (
                                                <h4 className="bold">Image Uploaded</h4>
                                            )}
                                        </div>
                                    </div>

                                </InputField>
                                {warning && (typeof coverimg === 'string' && coverimg.trim() === "") ?
                                    <span className="warning font-medium text-red-600">Cover Image is required</span> : ""}
                            </div>

                            <div className="right-content w-1/2">
                                <InputField enableInput={false} label="Add Product" enableButton={false}>
                                    <div className="input-box add-product-container border border-[--app-border] px-3 py-2 rounded-md">
                                        <button className="px-3 py-2 rounded-2xl text-base font-semibold bg-[--accent-1] text-white mb-2" onClick={() => {
                                            setAddProductModel(true)
                                        }}>Add</button>
                                        <div className="chipContainer">
                                            {productList.length !== 0 ? productList.map((item, index) => {
                                                return (
                                                    <Chip setProductList={setProductList} key={index} productList={productList} title={item.title} price={item.price} index={index} idData={id}></Chip>
                                                )
                                            }) : <h4>No product added yet</h4>}
                                        </div>
                                    </div>
                                </InputField>
                                {warning && productList.length === 0 ? <span className="warning font-medium text-red-600">Atleast one product is required</span> : ""}
                                <div className="title-button-section flex items-center gap-x-4 my-3">
                                    <h3 className="font-bold">Is Product Shoppable</h3>
                                    <CheckBox onCheck={isShoppable} state={isShoppable} setCheckboxValue={setIsShoppable}></CheckBox>
                                </div>
                            </div>

                            <div className="preview-button-container absolute bottom-0 right-0 hidden">
                                <button className="preview-button text-base font-medium px-3 py-2 rounded-2xl text-[--secondary-txt] border border-[--app-border]">PREVIEW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    </>
    )
}
