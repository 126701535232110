import React, { useState, useEffect, useRef } from 'react';
import Overall from './overall/overall';
import Realtime from './realtime';
import Map from './map';
import TopPerf from './topperf';
import DateRangePicker from '../Components/DateRangePicker';
import { FaCaretDown } from 'react-icons/fa';

import './analytics.css';
import { FaCalendarDays } from 'react-icons/fa6';
import api from '../../axiosConfig'

const Analytics = () => {
  
  const [data, setData] = useState(null);
  const [collections, setCollections] = useState(null);
  const [videos, setVideos] = useState(null);

  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [isCollectionsDropdownOpen, setIsCollectionsDropdownOpen] = useState(false);
  const [isVideosDropdownOpen, setIsVideosDropdownOpen] = useState(false);

  const [selectedCollections, setSelectedCollections] = useState(collections);

  const [videoOptions, setVideoOptions] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState(videoOptions);

  const [selectAllCollections, setSelectAllCollections] = useState(true);
  const [selectAllVideos, setSelectAllVideos] = useState(true);

  const collectionsButtonRef = useRef(null);
  const collectionsDropdownRef = useRef(null);
  const videosButtonRef = useRef(null);
  const videosDropdownRef = useRef(null);

  useEffect(() => {
    const fetchCollections = async () => {
        try {
            const res = await api.get(`/collections`);
            const data = await res.data.results;
            const collectionsData = data.map(collection => { return {id: collection.id, name: collection.name, videos: collection.videos}})
            setCollections(collectionsData);

            let allVideos = [];
            await Promise.all(collectionsData.map(async (collection) => {
                const res = await api.get(`/videos/collection/${collection.id}`);
                const data = await res.data;
                
                const collectionVideos = data.map(video => ({
                    id: video.id,
                    title: video.title,
                    description: video.description,
                    coverImage: video.coverImage
                }));
                
                allVideos = [...allVideos, ...collectionVideos]; 
            }));
            setVideos(allVideos);
        }
        catch (error) {
            console.log(error);
        }
    }
    fetchCollections()
  }, []);

  const fillMissingDates = (data, startDate, endDate) => {
    const dateRange = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange.map(date => {
      const existingData = data.find(d => d.x === date);
      return existingData ? existingData : { x: date, y: 0 };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedVideos?.length > 0) {
          const res = await api.post(`/analytics/overall`, {
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              collectionIds: selectedCollections?.map(collection => collection.id),
              videoIds: selectedVideos?.map(video => video.id),
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString()
            })
          });
          const data = await res.data;

          Object.keys(data).forEach(key => {
            if (data[key].xlabel === 'date') {
              data[key].data = fillMissingDates(data[key].data, startDate, endDate);
            }
          });

          setData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [selectedCollections, selectedVideos, startDate, endDate]);

  useEffect(() => {
    const options = new Set();
    selectedCollections?.map(collection => collection.videos).flat().map(video => options.add(video))
    setVideoOptions(videos?.filter(video => { return options.has(video.id)}))
    selectedCollections?.length === collections?.length ? setSelectAllCollections(true) : setSelectAllCollections(false)
  }, [selectedCollections, videos])

  useEffect(() => {
    if (selectAllCollections) {
      setSelectedCollections(collections)
    } else {
      if (selectedCollections?.length === collections?.length) {
        setSelectedCollections([])
      }
    }
  }, [selectAllCollections, collections])

  useEffect(() => {
    if (selectAllVideos) {
      setSelectedVideos(videoOptions)
    } else {
      if (selectedVideos?.length === videoOptions?.length) {
        setSelectedVideos([])
      }
    }
  }, [selectAllVideos, videoOptions])

  const handleSelectedCollectionsChange = (collection) => {
    const selection = selectedCollections?.includes(collection) ? selectedCollections.filter(c => c !== collection) : [...selectedCollections, collection]
    if (selection.length === collections?.length) {
      setSelectAllCollections(true)
    } else {
      setSelectAllCollections(false)
    }
    setSelectedCollections(selection)
  }

  const handleSelectedVideosChange = (video) => {
    const selection = selectedVideos?.includes(video) ? selectedVideos.filter(v => v !== video) : [...selectedVideos, video]
    if (selection.length === videoOptions?.length) {
      setSelectAllVideos(true)
    } else {
      setSelectAllVideos(false)
    }
    setSelectedVideos(selection)
  }

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        collectionsDropdownRef.current &&
        !collectionsDropdownRef.current.contains(event.target) &&
        collectionsButtonRef.current &&
        !collectionsButtonRef.current.contains(event.target) &&
        !videosButtonRef.current.contains(event.target) &&
        !videosDropdownRef.current?.contains(event.target)
      ) {
        setIsCollectionsDropdownOpen(false);
      }
      if (
        videosDropdownRef.current &&
        !videosDropdownRef.current.contains(event.target) &&
        videosButtonRef.current &&
        !videosButtonRef.current.contains(event.target) &&
        !collectionsButtonRef.current.contains(event.target) &&
        !collectionsDropdownRef.current?.contains(event.target)
      ) {
        setIsVideosDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard-nav">
        <button className="collections-selector" ref={collectionsButtonRef} onClick={() => setIsCollectionsDropdownOpen(!isCollectionsDropdownOpen)}>
          Collections
          <FaCaretDown className="collections-selector-dropdown-icon"/>
        </button>
        {isCollectionsDropdownOpen && 
          <div className='multiselect-root collections-dropdown-root'  ref={collectionsDropdownRef}>
            <input type="checkbox" checked={selectAllCollections} onChange={() => setSelectAllCollections(!selectAllCollections)} className='multiselect-option' /> Select All
            {collections?.map((collection, idx) => (
              <div>
                <input key={`collection-${idx}`} type="checkbox" checked={selectedCollections?.includes(collection)} onChange={() => handleSelectedCollectionsChange(collection)} className='multiselect-option' /> {collection.name}
              </div>
            ))}
          </div>
        }
        <button className="collections-selector"  ref={videosButtonRef}  onClick={() => setIsVideosDropdownOpen(!isVideosDropdownOpen)}>
          Videos
          <FaCaretDown className="collections-selector-dropdown-icon"/>
        </button>
        {isVideosDropdownOpen && 
          <div className='absolute top-12 left-12 multiselect-root videos-dropdown-root' ref={videosDropdownRef}>
            <input type="checkbox" checked={selectAllVideos} onChange={() => setSelectAllVideos(!selectAllVideos)} className='multiselect-option' /> Select All
            {videoOptions?.map((video, idx) => (
              <div>
                <input key={`video-${idx}`} type="checkbox" checked={selectedVideos?.includes(video)} onChange={() => handleSelectedVideosChange(video)} className='multiselect-option' /> {video.title}
              </div>
            ))}
          </div>
        }
        <button className="date-selector" onClick={() => setShowDatePicker(!showDatePicker)}>
          Date
          <div className='date-selector-icons'>
            <FaCalendarDays className="date-selector-calendar-icon"/>
            <FaCaretDown className="collections-selector-dropdown-icon"/>
          </div>
        </button>
        {showDatePicker && 
          <DateRangePicker 
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        }
      </div>
      <div className="dashboard-content">
        <div className="chart-container">
          <Overall data={data} />
        </div>
        <div className="chart-container">
          <Realtime selectedCollections={selectedCollections} selectedVideos={selectedVideos}/>
          <Map countriesData={data ? data["Views By Country"] : []}/>
        </div>
        <div className="chart-container">
          {/* <TopPerf
            selectedCollections={selectedCollections}
            selectedVideos={selectedVideos}
            startDate={startDate}
            endDate={endDate}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Analytics;