import React, { useState, useEffect, useCallback } from 'react';
import api from "../../axiosConfig.js";
import { getPreSignedUrl } from '../create-video/s3-upload.js';
import {  useNavigate } from 'react-router-dom';
import { AddSquare, Trash } from "iconsax-react";
import { toast } from "react-toastify";
import { deleteVideoLibrary } from "./logic.js";

const ListVideoLibrary = (props) => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);

  const Navigate = useNavigate();
  const getData = useCallback (async (url = "/video-library/") => {
    setLoading(true);
    try {
      const resp = await api.get(url);
      const data = resp.data.results;
      const nextUrl = resp.data.next;

      const updatedUrlVideos = await Promise.all(data.map(async (video) => {
        const url = await getPreSignedUrl(video.video);
        video.url = url.url;
        return video;
      }));
      setListData(prevData => [...prevData, ...updatedUrlVideos]);
      // setListData(prevData => [...prevData, ...data]);
      setNextUrl(nextUrl || null);
    } catch (err) {
      console.error("Error loading videos(listData) from library:", err);
    } finally {
      setLoading(false);
    }
  }, []);
  

  useEffect(() => {
    if (listData.length === 0) getData();
  }, [getData, listData.length]);

  useEffect(() => {
    const handleScroll = async() => {
      const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1;

      if (bottom && nextUrl && !loading) {
        await getData(nextUrl);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nextUrl, loading, getData]);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    e.preventDefault()
    
    deleteVideoLibrary(id, () => {
      setListData((prevListData) => prevListData.filter((item) => item.id !== id));
      toast.warning("Item deleted successfully");
    });

    console.log("id =", id);
  };

  return (
    <div className="p-4 w-full">
     <div className="flex items-center justify-between pt-20 mb-4">
        <h1 className="text-4xl font-bold">VIDEO LIST</h1>
        <div className='flex gap-x-4'>
          <button 
            className="bg-[--accent-1] font-bold text-xl px-4 py-3 text-white rounded-2xl flex items-center justify-center gap-x-4" 
            onClick={()=>{Navigate("/upload")}}
          >
            <span className=''><AddSquare size="1.75rem" color='#ffffff' /></span>
            <span className=''>ADD</span>
          </button>
        </div>
    </div>
    <ul className="grid md:grid-cols-4 sm:grid-cols-3 gap-8 mt-10">
        { listData.length === 0 ? 
        <span className="text-lg font-semibold">No videos in the list to display yet. Click the <span className="text-[--accent-1]">ADD</span> button.</span>
        : listData.map((video, index) => (
            <li key={index} className="relative flex items-center p-5 border rounded-lg"
            onDragLeaveCapture={(e) => {
              console.log(`Delete collection with id: ${video.id}`);
              handleDelete(e, video.id);
            }}
            >
                <div className="flex">
                  {/* <video className="w-full sm:w-72" src={video.video} controls /> */}
                  <video className="w-full sm:w-72" controls>
                    <source src={video.url} type="video/mp4" onClick={() => console.log("video.video =", video.video)} />
                    Your browser does not support the video tag.
                  </video>
                </div>
                  <button 
                    className="absolute top-2 right-2 px-2 py-2 sm:rounded-lg sm:px-1 sm:py-1 bg-[--accent-1] rounded-xl"
                    onClick={(e) => handleDelete(e, video.id)}
                  >
                    <Trash
                      size="1.75rem" 
                      color="#ffffff" 
                    />
                  </button>
            </li>
        ))}
    </ul>
    {loading ? (<p className="text-xl font-semibold my-10 mx-10">Loading...</p>) : (<></>)}
</div>
  );
};

export default ListVideoLibrary;
